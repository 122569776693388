var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("🚙Detalle itinerario")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observerItinerario",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"origen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Origen"}},[_c('b-form-select',{attrs:{"placeholder":"Ingrese el origen","state":errors[0] ? false : (valid ? true : null),"options":_vm.destinations},model:{value:(_vm.selectedOrderOperation.itinerary.ifrom),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation.itinerary, "ifrom", $$v)},expression:"selectedOrderOperation.itinerary.ifrom"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Destino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Destino"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.selectedOrderOperation.itinerary.ito),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation.itinerary, "ito", $$v)},expression:"selectedOrderOperation.itinerary.ito"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione un destino")]),_vm._l((_vm.destinations),function(destino,index){return _c('option',{key:index,domProps:{"value":destino.value}},[_vm._v(_vm._s(destino.text))])})],2),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Pax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"No. Pax"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Ingrese número de pax","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.selectedOrderOperation.itinerary.pax),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation.itinerary, "pax", $$v)},expression:"selectedOrderOperation.itinerary.pax"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Tipo de servicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Tipo de servicio"}},[_c('b-form-select',{attrs:{"options":_vm.optionsTrip,"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.selectedOrderOperation.itinerary.trip),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation.itinerary, "trip", $$v)},expression:"selectedOrderOperation.itinerary.trip"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Aerolínea"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Aerolínea"}},[_c('v-select',{attrs:{"label":"name","options":_vm.airlines,"state":errors[0] ? false : (valid ? true : null)},on:{"input":_vm.setAirline},model:{value:(_vm.aerolinea),callback:function ($$v) {_vm.aerolinea=$$v},expression:"aerolinea"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Número de vuelo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"No. de Vuelo"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Ingrese el vuelo","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.selectedOrderOperation.itinerary.flight),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation.itinerary, "flight", $$v)},expression:"selectedOrderOperation.itinerary.flight"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Hora de vuelo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{},[_c('label',{attrs:{"for":""}},[_vm._v("Hr. Vuelo")]),_c('br'),_c('vue-timepicker',{attrs:{"input-width":"100%","state":errors[0] ? false : (valid ? true : null),"placeholder":"Ingrese pickup"},scopedSlots:_vm._u([{key:"clearButton",fn:function(){return [_c('feather-icon',{staticStyle:{"color":"black"},attrs:{"icon":"XCircleIcon"}})]},proxy:true}],null,true),model:{value:(_vm.selectedOrderOperation.itinerary.flighttime),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation.itinerary, "flighttime", $$v)},expression:"selectedOrderOperation.itinerary.flighttime"}}),_c('label',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Hora Pick Up"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{},[_c('label',{attrs:{"for":""}},[_vm._v("Hr. Pick Up")]),_c('br'),_c('vue-timepicker',{attrs:{"input-width":"100%","state":errors[0] ? false : (valid ? true : null),"placeholder":"Ingrese pickup"},scopedSlots:_vm._u([{key:"clearButton",fn:function(){return [_c('feather-icon',{staticStyle:{"color":"black"},attrs:{"icon":"XCircleIcon"}})]},proxy:true}],null,true),model:{value:(_vm.selectedOrderOperation.itinerary.pickup),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation.itinerary, "pickup", $$v)},expression:"selectedOrderOperation.itinerary.pickup"}}),_c('label',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12 mt-2"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.saveDataTransferItinerary(_vm.selectedOrderOperation.id, _vm.selectedOrderOperation.itinerary)}}},[_vm._v(" Guardar ")])],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }