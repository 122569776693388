<template>
    <div>
        <b-table 
            class="mt-1"
            responsive
            show-empty
            empty-text="No hay registros para mostrar"
            small
            stacked="md"
            :busy.sync="isloadingProcess"
            :items="filteredOrdersOperation"
            :fields="fieldsOperations"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                    <strong> Cargando...</strong>
                </div>
            </template>
            <template #cell(information)="row">
                <b-button
                    :id="'nota'+row.item.id"
                    size="sm" 
                    variant="primary"
                    v-b-tooltip.hover
                    title="Ver información"
                ><b-icon-info-circle-fill></b-icon-info-circle-fill>
                </b-button>
                <b-popover :target="'nota'+row.item.id" variant="primary" triggers="focus">
                    <template #title>Additional Information</template>

                    <div class="text-center"> <b>Customer</b> </div>
                    <b>Name:</b> {{row.item.customer.name}} <br>
                    <b>Email:</b> {{row.item.customer.email}}<br>
                    <b>phone:</b> {{row.item.customer.phone}}<br>
                    <div v-if="row.item.beneficiario.fullname">
                    <div class="text-center"> <b>Beneficiary</b> </div>
                    <b>Name:</b> {{row.item.beneficiario.fullname}} <br>
                    <b>Email:</b> {{row.item.beneficiario.email}}<br>
                    <b>phone:</b> {{row.item.beneficiario.phone}}<br></div>
                    <div class="text-center"> <b>Payment</b> </div>
                    <b>Payment form:</b> {{row.item.paymentForm}}<br>
                    <b>Reference:</b> {{row.item.reference}}<br>
                    <div v-if="row.item.note !='' ">
                    <div class="text-center"> <b>Note</b></div>
                    {{row.item.note}}
                    </div>
                </b-popover><br>
                <b>Cliente:</b><br>{{row.item.customer.name}}<br>
                <div v-if="row.item.beneficiario.fullname"><b>Beneficiario:</b><br>{{row.item.beneficiario.fullname}}<br></div>
                <b>Fecha Operación:</b><br>{{row.item.operationdate}}
            </template>
            <template #cell(categoryname)="row">
                <span>{{row.item.categoryname}}</span><span v-if="row.item.subcategoryname">: <br> {{row.item.subcategoryname || ''}}</span>
            </template>
            <template #cell(itemsold)="row">
                <span style="font-size: 14px;">{{row.item.itemsold}}</span><br>
                <span class="badge badge-info mr-2" v-if="row.item.confirmationdate">
                    Confirmado <b-icon icon="check-all"></b-icon>
                </span>
                <span class="badge badge-warning mr-2" v-else>
                    No confirmado <b-icon icon="clock-history"></b-icon>
                </span>
                <span class="badge badge-success mr-2" v-if="row.item.operSupplier">
                    Proveedor <b-icon icon="check2-circle"></b-icon>
                </span>
                <span class="badge badge-danger mr-2" v-else>
                    Sin Proveedor <b-icon icon="x-square-fill"></b-icon>
                </span>
                <span class="badge badge-success mr-2" v-if="row.item.transferoperator">
                    Operador <b-icon icon="check2-circle"></b-icon>
                </span>
                <span class="badge badge-danger mr-2" v-else>
                    Sin Operador <b-icon icon="x-square-fill"></b-icon>
                </span>
                <span class="badge badge-info mr-2" v-if="row.item.orderdetailStatus == 4">
                    Operado <b-icon icon="check2-circle"></b-icon>
                </span>
                <span class="badge badge-warning mr-2" v-else>
                    No Operado <b-icon icon="clock-history"></b-icon>
                </span>
                <span class="badge p-0" v-if="row.item.incidentsInfo.status!=''">
                    <span v-if="row.item.incidentsInfo.id==3"  class="badge badge-warning" >incidente : {{row.item.incidentsInfo.status}} <b-icon icon="check2-circle"></b-icon>
                    </span>
                    <span v-if="row.item.incidentsInfo.id==2"  class="badge badge-info" >incidente : {{row.item.incidentsInfo.status}}<b-icon icon="check2-circle"></b-icon>
                    </span>
                    <span v-if="row.item.incidentsInfo.id==1"  class="badge badge-success" >incidente : {{row.item.incidentsInfo.status}}<b-icon icon="check2-circle"></b-icon>
                    </span>
                </span>
            </template>
            <template #cell(actions)="row">
                <b-button
                    v-if="isValidDate(row.item.operationdate)"
                    :title="row.item.orderdetailStatus == 4 ? 'Este servicio ya ha sido operado' : 'Marcar como operado'"
                    v-b-tooltip.hover
                    class="mr-1  mb-1"
                    size="sm"
                    :variant="row.item.orderdetailStatus == 4 ? 'info' : 'warning'"
                    :disabled="row.item.orderdetailStatus == 4 || row.item.orderdetailStatus == 6"
                    @click="updateStatusOperation('operar', row.item.id, 4)"
                >
                    <b-icon icon="calendar-plus-fill" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                    v-b-tooltip.hover
                    :title="row.item.confirmationdate ? 'Confirmado' : 'Confirmar'"
                    class="mr-1  mb-1"
                    size="sm"
                    :variant="row.item.confirmationdate ? 'info' : 'warning'"
                    @click="_confirmDetailOrder( row.item.id, true )"
                ><b-icon :icon="row.item.confirmationdate ? 'check-all ' : 'clock-history'" ></b-icon>
                </b-button>
                <b-button
                    title="Actualizar datos"
                    v-b-tooltip.hover
                    size="sm"
                     class="mr-1 mb-1"
                    variant="info"
                    @click="orderOperationData(row.item)"
                >
                    <feather-icon
                        icon="EditIcon"
                        size="14"
                    />
                </b-button>
                <b-button
                    title="Crear Incidencia"
                    v-b-tooltip.hover
                    size="sm"
                    class="mr-1 mb-1 d-none"
                    variant="warning"
                    :to="{ name: 'detail-incidents', params: { order: row.item.order, detail:row.item.id } }"
                >
                    <b-icon-bell-fill></b-icon-bell-fill>
                </b-button>

            </template>
            <template #cell(couponDoc)="row">
                <div v-if="row.item.categoryname =='Transfer'">
                    <a
                        v-if="row.item.imprimecupon"
                        :href="couponLink+row.item.order+'/'+row.item.productID+'/'+row.item.id+'/transfer/'" target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-primary btn-sm"
                        v-b-tooltip.hover
                        title="Ver Cupón"
                    ><b-icon-file-earmark-ruled-fill/>
                    </a>
                </div>
                <div v-if="row.item.categoryname !='Transfer'">
                    <a
                        v-if="row.item.imprimecupon"
                        :href="couponLink+row.item.order+'/'+ (row.item.productID ? row.item.productID : row.item.experience) +'/'+row.item.id+'/coupon/'" target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-primary btn-sm"
                        v-b-tooltip.hover
                        title="Ver Cupón"
                    ><b-icon-file-earmark-ruled-fill/>
                    </a>
                </div>
                <br>
                <div v-if="row.item.orderdetailbeo.id !== ''">
                    <a
                        :href="makeBEOLink(row.item)" target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-primary btn-sm"
                        v-b-tooltip.hover
                        title="Ver SOS"
                    >
                        <feather-icon icon="FileTextIcon"/>
                    </a>
                </div>
            </template>
        </b-table>
        <!-- Modal coon los detalles -->
        <Detail :payloadFilter="payloadFilter"/>
        <!-- end Modal coon los detalles -->

    </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

import Detail from '@/modules/operations/components/Detail'
import { toJson, showAlertMessage, sweetAlert } from '@/helpers/helpers'

export default {
    props:{
        filteredOrdersOperation:{
            type: Array,
            required: true
        },
        fieldsOperations:{
            type: Array,
            required: true
        },
        currentPage:{
            type: Number,
            required: true
        },
        perPage:{
            type: Number,
            required: true
        },
        filter:{
            type: String,
            required: false
        },
        filterOn:{
            type: Array,
            required: true
        },
        sortBy:{
            type: String,
            required: true
        },
        sortDesc:{
            type: Boolean,
            required: true
        },
        sortDirection:{
            type: String,
            required: true
        },
        onFiltered:{
            type: Function,
            required: true
        },
        fechaActual:{
            type: String,
            required: true
        },
        clearFilter:{
            type: Function,
            required: true
        },
        payloadFilter:{
            type: Object,
            required: true
        },
        isloadingProcess:{
            type: Boolean,
            required: true
        },
    },
    components:{
        Detail
    },
    
    data(){
        return {
            couponLink: process.env.VUE_APP_ORDER_DETAIL_COUPON_PDF,
            BEOLink: process.env.VUE_APP_ORDER_DETAIL_BEO_PDF,
        }
    },
    computed:{
        ...mapState('auth',['user']),
        ...mapState('operation',['suppliers','operators']),
    },
    methods:{
        ...mapActions('operation',['fetchOrdersOperation','fetchSuppliers','fetchOperatorsBySupplier','updateOrderOperation','confirmDetailOrder']),
        ...mapMutations('operation',['setOrderOperationSelected','setSupplierOrderOperation','unsetOperators','setOperatorOrderOperation']),
        ...mapMutations("auth", ["access"]),
        //abre la ventana modal
        async orderOperationData( item ){
            const { id } = item
            await this.setOrderOperationSelected(item)
            await this.fetchSuppliers(id)
            //si solo hay un proveedor en proveedores, setear el primer valor, 2 porque el primer elemento es la opcion en blanco
            if(this.suppliers && this.suppliers.length == 2 && !item.operSupplier){
                await this.setSupplierOrderOperation(this.suppliers[1].value)
                await this.fetchOperatorsBySupplier(this.suppliers[1].value)
            }
            if(item.operSupplier){
                await this.setSupplierAndGetOperators(item.operSupplier)
                if(this.operators && this.operators.length == 2 && !item.transferoperator){
                    await this.setOperator(this.operators[1].value)
                }
                if( this.operators && item.transferoperator){
                    const operador = this.operators.find(ope => ope.value === item.transferoperator)
                    await this.setOperator(operador.value)
                }
            }


            this.$root.$emit('bv::show::modal', 'modal-item-detail')
        },
        async setSupplierAndGetOperators(supplier){
            if(supplier){
                await this.setSupplierOrderOperation(supplier)
                await this.fetchOperatorsBySupplier(supplier)
            } else this.unsetOperators()
        },
        setOperator(operator){
            if(operator) this.setOperatorOrderOperation(operator)
        },
        async updateStatusOperation(tipo, order, status, reference="" ) {
            const { idUser } = this.user
            const payload = {tipo, order, status, reference, idUser }
            const item = this.filteredOrdersOperation.find( item => item.id === order )
            const { operSupplier } = toJson(item)
            // payload para SweetAlert. Parámetros bajo orden
            const swalert = {
                title: 'Operación de servicio',
                message: '¿Desea operar este servicio?',
                icon: 'question',
                confirmButton: 'Operar',
                cancelButton: 'Cancelar',
            }

            const {isConfirmed} = await sweetAlert(swalert)
            
            if (isConfirmed) {
                if (operSupplier) {
                    await this.updateOrderOperation(payload)
                    await this.getOperationsOrders()
                } else showAlertMessage(`Alerta`, 'BellIcon', `No se permite operar hasta agregarle un proveedor`, 'danger', 4000, 'bottom-right')
            } else showAlertMessage(`Operación cancelada`, 'BellIcon', `No se ha operado el servicio`, 'danger', 4000, 'bottom-right')
        },
        makeBEOLink(item){
            let link = ''
            item.beolink = ''
            link = this.BEOLink+item.order+'/'+item.id
            item.beolink = link
            return link
        },
        async _confirmDetailOrder( orderdetail, accion ){
            let code
            const { idUser } = this.user
            const payload = { orderdetail, idUser, accion, code }
            const swalert = {
                title: 'Confirmación de servicio',
                message: '¿Desea confirmar este servicio?',
                icon: 'question',
                confirmButton: 'Confirmar',
                cancelButton: 'Cancelar',
            }

            const {isConfirmed} = await sweetAlert(swalert)

            if (isConfirmed) {
                const textalert = {
                    title: 'Código de Confirmación',
                    message: 'Ingrese el código de confirmación',
                    cancelButton: 'Cancelar',
                    confirmButton: 'Confirmar',
                    textfield: true,
                    icon: 'warning'
                }
                const {Result, isConfirmed:Confirmed} = await sweetAlert(textalert)
                if (Confirmed) {
                    payload.code = Result
                    await this.confirmDetailOrder(payload)
                    await this.getOperationsOrders()
                } else showAlertMessage(`Confirmación cancelada`, 'BellIcon', `No se ha confirmado el servicio`, 'danger', 4000, 'bottom-right',)
            } else showAlertMessage(`Confirmación cancelada`, 'BellIcon', `No se ha confirmado el servicio`, 'danger', 4000, 'bottom-right',)
        },
        async getOperationsOrders(){
            this.$emit('get-Orders-By-Filters') 
        },
        getIncidentsItem(item){
            // this.setOrderOperationSelected(item)
            this.$router.push({name: 'detail-incidents', params: { id: item.id }})
        },
        isValidDate(date){
            const today = this.fechaActual.split('-')
            const operationdate = date.split('-')
            const valid = operationdate <= today
            return valid
        }

        
    }
}
</script>
