<template>
    <b-card no-body>
        <b-card-body>
            <Filters
              :pageOptions="pageOptions"
              :hotels="hotels"
              :categories="categoriasFiltro"
              :currentDate="currentDate"
              :currentNextDate="currentNextDate"
              :categorySelect="category"
              :hotelSelect="hotel"
              :typeSelect="type"
              :perPage="perPage"
              :filter="filter"
              @filtrar-resultado-tabla="filtrarResultadoTabla"
              @clear-filtrar-resultado-tabla="clearFiltrarResultadoTabla"
              @change-per-page="changePerPage"
              @set-Paylod-Orders-By-Filters="setDataPayloadFilter"
              @get-Orders-By-Filters="getOrdersOperation"
              @clear-filter="resetFilter"
            /> 
            <!-- Tabla de resultados -->
            <TableOperations
              :filteredOrdersOperation="filteredOrdersOperation"
              :fieldsOperations="fieldsOperations"
              :currentPage="currentPage"
              :perPage="perPage"
              :filter="filter"
              :filterOn="filterOn"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :sortDirection="sortDirection"
              :onFiltered="onFiltered"
              :fechaActual="fechaActual"
              :clearFilter="clearFiltrarResultadoTabla"
              :isloadingProcess="isloadingProcess"
              :payloadFilter="{currentDate, currentNextDate, category, hotel, type}"
              @get-Orders-By-Filters="getOrdersOperation"
            />
            <b-pagination
              v-if="filteredOrdersOperation.length && !isloadingProcess"
              v-model="currentPage" :total-rows="filteredOrdersOperation.length" :per-page="perPage" align="right" class="my-0">
            </b-pagination>
        </b-card-body>
    </b-card>
</template>

<script>

import * as moment from 'moment'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import Filters from '@/modules/operations/components/Filters'
import TableOperations from '@/modules/operations/components/TableOperations'

import { currentDate } from '@/helpers/helpers'

import { fieldsOperations } from '@/data/data'

export default {
  components: {
    Filters,
    TableOperations,
  },
  async created() {
    const isAffiliated = this.isAffiliated()
    if(isAffiliated) this.$router.push({name: 'sales',})
    else await this.getInitialContentOperations()
  },
  data() {
    return {
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      currentNextDate: moment(new Date()).format('YYYY-MM-DD'),
      category: 0,
      hotel: 0,
      type: '',
      fechaActual: currentDate(),
      selected: null,
      fieldsOperations,
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 20, 30, { value: 200, text: "Todos" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    ...mapGetters('operation',['filteredOrdersOperation']),
    ...mapState('auth',['user']),
    ...mapState('start',['hotels']),
    ...mapState('operation',['isloadingProcess','categories']),
   
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    categoriasFiltro(){
      const categorias = this.categories.filter( cat => Number.isInteger(cat.id))
      const filteredCats = categorias.filter(cat => cat.operation == true)
      return filteredCats
    }
  },
  methods: {
    ...mapActions('operation',['fetchOrdersOperation','fetchSuppliers','fetchOperatorsBySupplier','updateOrderOperation','confirmDetailOrder','getInitialContentOperations']),
    ...mapMutations('operation',['setOrdersOperation']),
    isAffiliated(){
      return this.user.group === 'Affiliate' ? true : false
    },
    onFiltered(filteredOrdersOperation) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredOrdersOperation.length
      this.currentPage = 1
    },
    async resetFilter(){
      this.currentDate = moment(new Date()).format('YYYY-MM-DD')
      this.currentNextDate = moment(new Date()).format('YYYY-MM-DD')
      this.category = 0
      this.hotel = 0
      this.type = ''
      this.filter = null //el buscador 
      this.perPage = 20,
      this.setOrdersOperation([])
    },
    async setDataPayloadFilter(data){
      const payload = JSON.parse(JSON.stringify(data))
      this.currentDate = payload.fechaInicio
      this.currentNextDate = payload.fechaFin
      this.category = payload.categoria
      this.subcategory = payload.subcategoria
      this.hotel = payload.hotel
      this.type = payload.tipo
    },
    async getOrdersOperation(){
      const payload = {
        categoria: this.category,
        subcategoria: this.subcategory,
        fechaFin: this.currentNextDate,
        fechaInicio: this.currentDate,
        hotel: this.hotel,
        tipo: this.type
      }
      if (!payload.subcategoria) delete payload.subcategoria
      await this.fetchOrdersOperation(payload)
    },
    changePerPage( data ){
      this.perPage = data
    },
    filtrarResultadoTabla( data ){
      this.filter = data
    },
    clearFiltrarResultadoTabla(){
      this.filter = ''
    }
  }
}
</script>
