<template>
    <b-modal
        v-if="selectedOrderOperation"
        id="modal-item-detail" :title="'Orden: '+selectedOrderOperation.order+'-'+selectedOrderOperation.itemsold"
        size="lg"
        no-close-on-backdrop
        hide-footer
    >
        <b-tabs>
            <TabAutorization @get-operations-after-save="getOperationsOrders"/>
            <TabGeneralDetail @get-operations-after-save="getOperationsOrders"/>
            <!-- <TabItinerary v-if="selectedOrderOperation.categoryname == 'Transfer'"/> -->
        </b-tabs>
    </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import TabGeneralDetail from '@/modules/operations/components/TabGeneralDetail'
import TabAutorization from '@/modules/operations/components/TabAutorization'
import TabItinerary from '@/modules/operations/components/TabItinerary'

export default {
    props:{
        payloadFilter:{
            type: Object,
            required: true
        },
    },
    components:{
        TabGeneralDetail,
        TabItinerary,
        TabAutorization
    },
    computed:{
        ...mapState('operation',['isloadingProcess','selectedOrderOperation']),
    },
    methods:{
        ...mapActions('operation',['fetchOrdersOperation']),
        async getOperationsOrders(){
            const { category, currentNextDate, currentDate, hotel, type } = this.payloadFilter
            const payload = {
                categoria: category,
                fechaFin: currentNextDate,
                fechaInicio: currentDate,
                hotel,
                tipo: type
            }
            await this.fetchOrdersOperation(payload)
        }
    }
}
</script>
