<template>
    <b-tab >
      <template #title>
        <span>🚙Detalle itinerario</span>
      </template>
      <ValidationObserver ref="observerItinerario" v-slot="{ invalid }" >
        <b-row>
          <b-col md="6">
            <ValidationProvider rules="required" name="origen">
              <b-form-group slot-scope="{ valid, errors }" label="Origen">
                <b-form-select
                  placeholder="Ingrese el origen" 
                  :state="errors[0] ? false : (valid ? true : null)" 
                  v-model="selectedOrderOperation.itinerary.ifrom"
                  :options="destinations">               
                </b-form-select> 
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider name="Destino" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label="Destino">
              <b-form-select 
                  :state="errors[0] ? false : (valid ? true : null)"
                  v-model="selectedOrderOperation.itinerary.ito">
                  <option value="">Seleccione un destino</option>                                            
                  <option v-for="(destino, index) in destinations" :key="index" :value="destino.value">{{destino.text}}</option>
              </b-form-select>
                  <b-form-invalid-feedback>
                  {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
          </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Pax">
              <b-form-group slot-scope="{ valid, errors }" label="No. Pax">
                <b-form-input
                  type="number"
                  placeholder="Ingrese número de pax" 
                  v-model="selectedOrderOperation.itinerary.pax"                         
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input> 
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Tipo de servicio">
              <b-form-group slot-scope="{ valid, errors }" label="Tipo de servicio">
                <b-form-select
                  :options="optionsTrip"
                  v-model="selectedOrderOperation.itinerary.trip"
                  :state="errors[0] ? false : (valid ? true : null)" 
                >                                                                 
                </b-form-select>  
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Aerolínea">
              <b-form-group slot-scope="{ valid, errors }" label="Aerolínea">
                <v-select
                  label="name"
                  @input="setAirline"
                  v-model="aerolinea" 
                  :options="airlines"
                  :state="errors[0] ? false : (valid ? true : null)"                 
                />
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Número de vuelo">
              <b-form-group slot-scope="{ valid, errors }" label="No. de Vuelo">
               <b-form-input
                  type="text"
                  placeholder="Ingrese el vuelo" 
                  v-model="selectedOrderOperation.itinerary.flight"                         
                  :state="errors[0] ? false : (valid ? true : null)" >
                </b-form-input>                                                               
                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Hora de vuelo">
              <div slot-scope="{ valid, errors }"> 
              <label for="">Hr. Vuelo</label><br>
              <vue-timepicker                
                  v-model="selectedOrderOperation.itinerary.flighttime"                         
                  input-width="100%"
                  :state="errors[0] ? false : (valid ? true : null)"
                  placeholder="Ingrese pickup"                                                                      
              >
                  <template v-slot:clearButton>
                  <feather-icon icon="XCircleIcon" style="color:black;"/>
                  </template>
              </vue-timepicker>
              <label style="color:red;">
                  {{ errors[0] }} 
              </label>                    
              </div>
          </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider rules="required" name="Hora Pick Up">
              <div slot-scope="{ valid, errors }"> 
                <label for="">Hr. Pick Up</label><br>
                <vue-timepicker                
                  v-model="selectedOrderOperation.itinerary.pickup"                       
                  input-width="100%"
                  :state="errors[0] ? false : (valid ? true : null)"
                  placeholder="Ingrese pickup"                                                                      
                >
                <template v-slot:clearButton>
                  <feather-icon icon="XCircleIcon" style="color:black;"/>
                </template>
                </vue-timepicker>
              <label style="color:red;">
                  {{ errors[0] }} 
              </label>                    
            </div>
          </ValidationProvider>
          </b-col>
          <b-col md="12 mt-2">
            <div class="float-right">
              <b-button
                variant="primary"
                :disabled="invalid"
                @click="saveDataTransferItinerary(selectedOrderOperation.id, selectedOrderOperation.itinerary)"
              > Guardar
              </b-button>
            </div>
          </b-col>
        </b-row>                  
      </ValidationObserver> 
    </b-tab> 
</template>
<script>   

import {mapActions,  mapState } from 'vuex'

import { optionsTrip } from '@/data/data'   
import vSelect from 'vue-select'
//timepicker 24hrs
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    vSelect,
    VueTimepicker

  },     
  data() {
    return {
      optionsTrip        
    }
  },  
  computed: {    
    ...mapState('auth',['user']),      
    ...mapState('start',['destinations','airlines']),
    ...mapState('operation',['selectedOrderOperation']),   
    // computedAerolinea(){
    //   let aerolinea = this.airlines.find( aero => aero.name === this.selectedOrderOperation.itinerary.airline)
    //   return aerolinea.name || []                     
    // },
    aerolinea: {
      get(){
        const aeroLinea = this.selectedOrderOperation.itinerary.airline ? this.selectedOrderOperation.itinerary.airline : ''
        const aerolinea =  this.airlines.find( aerolinea => aerolinea.name === aeroLinea )
        return aerolinea || []
      },
      set( airline ){
        const name  = airline ? airline.name : null
        return this.airlines.find( airline => airline.name === name )             
      } 
    },      
  },
  methods: {                       
    ...mapActions('operation',['updateItineraryDetail']),  
    setAirline( airline ){       
      if(airline){
        this.selectedOrderOperation.itinerary.airline = airline.name          
      }
      if( !airline ){
          this.selectedOrderOperation.itinerary.airline  = null            
      }
    },                               
    async saveDataTransferItinerary(id, itinerary){
      // const aerolinea = this.airlines.find( aerolinea => aerolinea.name === itinerary.airline)
      itinerary.ordersdetail = id  
      itinerary.idUser = this.user.idUser         
      // console.log(itinerary)   
      await this.updateItineraryDetail(itinerary)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>