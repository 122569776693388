<template>
    <div>
        <b-row>
            <b-col md="4">
                <b-form-group label="Fecha inicio">               
                    <b-form-input id="dateStart" type="date" v-model="fechaInicio" @input="setPayloadFilter">
                    </b-form-input>           
                </b-form-group >                
            </b-col>
            <b-col md="4">
                <b-form-group label="Fecha Fin">               
                    <b-form-input id="dateEnd" type="date" v-model="fechaFin" @input="setPayloadFilter">
                    </b-form-input>           
                </b-form-group >                
            </b-col>
            <b-col md="4">
                <label>Buscar en la tabla</label>
                <b-input-group >
                    <b-form-input type="search" placeholder="Buscar" v-model="filtroText" @input="filtrarInfo"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button variant="danger" class="btn-sm" :disabled="!filtroText" @click="clearFiltroText" >
                            <b-icon-trash-fill></b-icon-trash-fill> 
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col :md="colSize">
                <b-form-group label="Por Hotel">
                    <select class="custom-select" v-model="hotel" @change="setPayloadFilter">
                        <option disabled :value="0">Seleccione un hotel</option>
                        <option v-for="hotel in showHotels" :value="hotel.id" :key="hotel.id">
                        {{hotel.name}}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col  :md="colSize">
                <b-form-group label="Categorías">
                    <select class="custom-select" v-model="category" @change="setPayloadFilter">
                        <option disabled :value="0">Seleccione una categoria</option>
                        <option v-for="category in categories" :value="category.id" :key="category.id">
                            {{category.name}}
                        </option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col :md="colSize" v-if="subcategories">
                <b-form-group label="Subcategorías">
                <select class="custom-select" v-model="subcategory" @change="setPayloadFilter">
                    <option :value="null">Todas las subcategorías</option>
                    <option value="0">Sin subcategoría</option>
                    <option v-for="subcat in subcategories" :value="subcat.value" :key="subcat.value">
                        {{subcat.text}}
                    </option>
                </select>
                </b-form-group>
            </b-col>
             <b-col :md="colSize"> 
                <b-form-group label="No. de resultados">
                    <b-input-group>
                        <b-form-select :options="pageOptions" v-model="porPagina" @change="changePerPage">
                        </b-form-select>
                    </b-input-group >
                </b-form-group>
            </b-col>
        </b-row>
        <b-row >
            <b-col>
                <b-button variant="danger" class="mr-1" @click="clearFilter"><feather-icon icon="TrashIcon" size="16" /> Limpiar
                </b-button>
            </b-col>
            <b-col>
                <div class="float-right">
                    <Excel :fechaActual="fechaExcel" :user="user" class="mr-1" v-if="filteredOrdersOperation.length > 0 && user"></Excel>
                    <b-button variant="success" @click="getResultsOrderFilter" >
                        <b-icon icon="search"></b-icon><span v-if="windowWidth > 767"> Buscar</span>
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import * as moment from 'moment'
import { mapState, mapGetters } from 'vuex'  
import { showAlertMessage, currentDate, sortalphabetically } from '@/helpers/helpers'
import Excel from '@/modules/operations/components/Excel'

export default {
    props:{
        pageOptions:{
            type: Array,
            required: true
        },
        perPage:{
            type: Number,
            required: false
        },
        filter:{
            type: String,
            required: false
        },
        hotels:{
            type: Array,
            required: true
        },
        categories:{
            type: Array,
            required: true
        },
        currentDate:{
            type: String,
            required: true
        },
        currentNextDate:{
            type: String,
            required: true
        },
        categorySelect:{
            type: Number,
            required: true
        },
        hotelSelect:{
            type: Number,
            required: true
        },
        typeSelect:{
            type: String,
            required: true
        },
    },
    components:{
        Excel
    },
    async mounted(){
        await this.setPayloadFilter()
        // await this.getResultsOrderFilter()
    },
    data(){
        return{
            fechaInicio: this.currentDate,
            fechaFin: this.currentDate,
            hotel: this.categorySelect,
            category: this.hotelSelect,
            type: this.typeSelect,
            filtroText: this.filter,
            porPagina: this.perPage,
            fechaExcel: currentDate(),
            subcategories: null,
            subcategory: null
        }
    },
    computed:{
      ...mapState('auth',['user']),
      ...mapGetters('operation',['filteredOrdersOperation']),
      ...mapState('appConfig',['windowWidth']),
        showHotels(){
            const hotels = this.hotels.filter(hotel => hotel.showinweb == true)
            return sortalphabetically(hotels)
        },
        colSize(){
            return this.subcategories !== null ? '3' : '4'
        }
    },
    methods:{
        setPayloadFilter(){
            this.checkIfHasSubcategory()
            const isValidDates = moment(this.fechaFin).isSameOrAfter(this.fechaInicio, 'day')
            const payload = {
                categoria: this.category,
                subcategoria: this.subcategory,
                fechaFin: this.fechaFin,
                fechaInicio: this.fechaInicio,
                hotel: this.hotel,
                tipo: this.type
            }
            if (!payload.subcategoria) delete payload.subcategoria
            if (isValidDates) this.$emit('set-Paylod-Orders-By-Filters', payload)
            else showAlertMessage('Fechas incorrectas', 'BellIcon', `😡 Fecha fin no debe ser menor a fecha de inicio`, 'danger', 7000, 'bottom-right')
        },
        async getResultsOrderFilter(){
            // if (!this.subcategory && this.subcategories) return showAlertMessage('Faltan campos', 'BellIcon', `Por favor, seleccione una subcategoría`, 'warning', 4000, 'bottom-right')
            // else 
            this.$emit('get-Orders-By-Filters') 
        },
        filtrarInfo(){
            this.$emit('filtrar-resultado-tabla', this.filtroText)
        },
        clearFiltroText(){
            this.filtroText = ''
            this.$emit('clear-filtrar-resultado-tabla')
        },
        changePerPage( ){
            this.$emit('change-per-page', this.porPagina)
        },
        clearFilter(){
            this.$emit('clear-filter')
        },
        checkIfHasSubcategory(){
            // busco si la categoría tiene subcategorias
            const valid = this.categories.find(cat => cat.id == this.category)
            if (valid?.subcategory.length > 0) this.subcategories = valid.subcategory
            else { // si no hay subcategoría, borra los valores actuales
                this.subcategories = null
                this.subcategory = null
            }
        }
    }
}
</script>