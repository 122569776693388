var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" 🗃️ Proveedor/Operador")])]},proxy:true}])},[_c('ValidationObserver',{ref:"observer2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('strong',[_vm._v("Seleccionar o actualizar proveedor/operador")]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"proveedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Proveedor"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null),"options":_vm.suppliers},on:{"change":function($event){return _vm.setSupplierAndGetOperators(_vm.selectedOrderOperation.supplier)}},model:{value:(_vm.selectedOrderOperation.supplier),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation, "supplier", $$v)},expression:"selectedOrderOperation.supplier"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"operador"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Operador"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : (valid ? true : null),"options":_vm.operators},model:{value:(_vm.selectedOrderOperation.operator),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation, "operator", $$v)},expression:"selectedOrderOperation.operator"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.saveDataSupplairOperator(_vm.selectedOrderOperation.id, _vm.selectedOrderOperation.supplier, _vm.selectedOrderOperation.operator)}}},[_vm._v(" Guardar ")])],1)]),(!_vm.selectedOrderOperation.operSupplier || !_vm.selectedOrderOperation.transferoperator)?_c('b-col',{staticClass:"mt-1",attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" 😊 Considere guardar por lo menos un proveedor.")])])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }