<template>
    <b-tab>
        <template #title>
            <span> 🗃️ Proveedor/Operador</span>
        </template>
        <ValidationObserver ref="observer2" v-slot="{ invalid }" >
            <strong>Seleccionar o actualizar proveedor/operador</strong>
            <b-row class="mt-1">
                <b-col md="6">
                    <ValidationProvider rules="required" name="proveedor">
                        <b-form-group slot-scope="{ valid, errors }" label="Proveedor">
                            <b-form-select
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="selectedOrderOperation.supplier"
                                :options="suppliers"
                                @change="setSupplierAndGetOperators(selectedOrderOperation.supplier)">
                            </b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="6">
                    <ValidationProvider rules="required" name="operador">
                        <b-form-group slot-scope="{ valid, errors }" label="Operador">
                            <b-form-select
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="selectedOrderOperation.operator"
                                :options="operators">
                                </b-form-select> 
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <div class="float-right">
                        <b-button
                            variant="primary"
                            :disabled="invalid"
                            @click="saveDataSupplairOperator(selectedOrderOperation.id, selectedOrderOperation.supplier, selectedOrderOperation.operator)"
                        > Guardar
                        </b-button>
                    </div>
                </b-col>
                <b-col class="mt-1" md="12" v-if="!selectedOrderOperation.operSupplier || !selectedOrderOperation.transferoperator">
                    <b-alert variant="danger" show>
                        <div class="alert-body"> 😊 Considere guardar por lo menos un proveedor.</div>
                    </b-alert>
                </b-col>
            </b-row>
        </ValidationObserver>
    </b-tab>
</template>
<script>

import {mapActions, mapState, mapMutations} from 'vuex'
export default {
    computed: {
        ...mapState('operation',['selectedOrderOperation','suppliers','operators']),      
        ...mapState('auth',['user']),
    },
    methods: {
        ...mapActions('operation',['fetchOperatorsBySupplier','updateSupplierOperator']),
        ...mapMutations('operation',['setSupplierOrderOperation','unsetOperators','setOperatorOrderOperation']),
        async setSupplierAndGetOperators(supplier){ 
            if(supplier){
                this.setSupplierOrderOperation(supplier)
                await this.fetchOperatorsBySupplier(supplier)
            } else this.unsetOperators()
        },
        setOperator(operator){
            if(operator) this.setOperatorOrderOperation(operator)
        },
        async saveDataSupplairOperator(orderdetail, idSupplier, idOperador){
            const payload = { orderdetail, idSupplier, idOperador, idUser: this.user.idUser} 
            payload.idOperador = idOperador > 0 ? idOperador : null
            //console.log(payload)
            await this.updateSupplierOperator( payload )
            await this.getOperations()
        },
        async getOperations(){
            this.$emit("get-operations-after-save")
        }
    }
}
</script>
