<template>
    <b-tab active>
        <template #title>
            <span> 🔒 Confirmación</span>
        </template>
        <ValidationObserver ref="observer" v-slot="{ invalid }" >
            <strong>Confirmación del Proveedor</strong>
            <b-row class="mt-1">
                <b-col md="12">
                    <ValidationProvider rules="" name="Código">
                        <b-form-group slot-scope="{ valid, errors }">
                            <b-form-input
                                type="text"
                                placeholder="Enter the confirmation code" 
                                v-model="selectedOrderOperation.confirmationcode"
                                :state="errors[0] ? false : (valid ? true : null)" >
                            </b-form-input> 
                            <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="12">
                    <div class="float-right">
                        <b-button
                            variant="primary"
                            :disabled="invalid"
                            @click="updateStatusOperation('autorizacion', selectedOrderOperation.id, selectedOrderOperation.orderdetailStatus, selectedOrderOperation.confirmationcode)"
                        >Guardar
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </ValidationObserver>
    </b-tab>
</template>
<script>

import {mapActions, mapState} from 'vuex'
export default {
    computed: {
        ...mapState('operation',['selectedOrderOperation']),
    },
    methods: {
        ...mapActions('operation',['updateOrderOperation']),
        async updateStatusOperation(tipo, order, status, code) {
            const payload = {tipo, order, status, code}
            await this.updateOrderOperation(payload)
            await this.getOperations()
        },
        async getOperations(){
            this.$emit("get-operations-after-save")
        }
    }
  }
</script>
