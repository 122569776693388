<template>
  <download-excel
    class="btn btn-success"
    :data="setDataInExcel"
    :fields="json_fields"
    worksheet="listado"
    :name="fileName"
  >
    <b-icon icon="cloud-download" aria-hidden="true"></b-icon><a :class="windowWidth < 767 ? 'd-none' : '' " > Excel</a>
  </download-excel>
</template>
<script>
import { mapGetters, mapState} from 'vuex'

export default {
  data() {
    return { 
      fileName: 'SalesPOS-'+this.fechaActual+'-'+this.user.name.split(' ').join('-')+'-'+this.user.lastname.split(' ').join('-')+'.xls',
      //campos a mostrar en el excel
      json_fields: {
        'NO. POS':              "order",
        'FECHA OPERACIÓN':      "operationdate",
        'NOMBRE CLIENTE':       'customername',
        'NOMBRE PRODUCTO':      "itemsold",
        'NO. PAX':              'qty',
        'NO. HABITACIÓN':       'beneficiarioRoom',
        'NO. CONFIRMACIÓN':     'confirmationcode',
        'NOTAS':                'note',
        'VENDEDOR':             "saleuser_name",
        'ESTATUS PRODUCTO' :    'orderdetailstatusname',
      },
      json_meta: [[{key: "charset", value: "utf-8",}],],
    }
  },
  props:{
    fechaActual: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed:{
    ...mapGetters('operation',['filteredOrdersOperation']),
    ...mapState('appConfig',['windowWidth']),
    setDataInExcel(){
      let dataExcel = []
      this.filteredOrdersOperation.forEach(order => {
        dataExcel.push({
          order: order.order,
          saleuser_name: order.saleuser_name + ' ' + order.saleuser_lastname,
          operationdate: order.operationdate,
          customername: order.customer.name,
          qty: order.qty,
          beneficiarioRoom: order.beneficiario.room,
          confirmationcode: order.confirmationcode,
          note: order.note,
          itemsold: order.itemsold,
          orderdetailstatusname: order.orderdetailstatusname,
        })
      })
      return dataExcel
    }
  }
}
</script>
<style>
.btnExcel:hover{
  color: white;
}
</style>
